import React, { act, useContext } from 'react'
import {useState, useEffect} from 'react'
import ActorContext from '../../context/ActorContext'
import Inputbox from '../shared/Inputbox'
import UserContext from '../../context/UserContext'
import Card from '../shared/Card'
import { LargerFont } from '../shared/Constants'
import DataDependencyContext from '../../context/DataDependencyContext'
import SupabaseContext from '../../context/SupabaseContext'

function ListMovie() {
  const {findMovieByActor} = useContext(ActorContext)

  const {isL1DataLoaded} = useContext(DataDependencyContext)
  const [movieList, setMovieList] = useState([])

  const [textInput, setTextInput] = useState('')


  useEffect(() => {
    if (isL1DataLoaded) {
      
    }
  }, [isL1DataLoaded])

  

  const parseInput = async (e) => {
    const value = e.target.value
    if (value.length !== 3) {
      setMovieList([])
    }

    var movieList = await findMovieByActor(value)    
    setMovieList(movieList)
  }

  

  return (
    <Card>
      <div className={`${LargerFont} mb-2`}>List Movie of an Actor</div>
      
      <Inputbox
        name="textInput"
        id="textInput"
        placeholder='Actor ID'
        value={textInput}
        onChange={parseInput}
        className='mb-4'
      />

      {movieList.map((item, index) => (
          <div key={index}>{item.movie_id}</div>
      ))}
      
        
    </Card>
  )
}

export default ListMovie